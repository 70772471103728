import { Form, Col, Row } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import '../../css/App.css';
import '../../css/Coding.css';
import { FormInput } from '../form/Input';
import { FormSelect } from '../form/Select';
import { FormTextArea } from '../form/TextArea';
import { Roles, Status } from '../../enums';
import { ReactElement, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { t, isNullOrUndefined } from '../../helpers';
import { VictimForm } from '../victim-form/VictimForm';
import NewWindow from 'rc-new-window';
import { IValidation } from '../../types';
import { useRole } from '../../hooks';

export const CodingForm = (props: any): ReactElement => {
  const { field, codeData = [], control, index, isFormReadOnly } = props;
  const status = field.Coding_Status;
  const { accounts } = useMsal();
  const username = accounts[0]?.username.split("@", 1);

  const certain = [
    { value: false, label: 'Certain' },
    { value: true, label: 'Uncertain' },
  ];
  const cyberSupervisorValues = [
    { value: 0, label: 'Cyber Certain' },
    { value: 1, label: 'Cyber Uncertain' },
    { value: 2, label: 'Not Cyber Certain' },
    { value: 3, label: 'Not Cyber Uncertain' },
  ];
  const cyberFinalValues = [
    { value: 0, label: 'Not Cyber' },
    { value: 1, label: 'Cyber' },
  ];

  const modifyValidationFunction = (validationFunction: (value: any) => IValidation, isReadOnly: boolean) => {
    if (isReadOnly) {
      return () => true;
    }
    return validationFunction;
  }

  const createValidationOnValueExists = (message: string) => ((value: any): IValidation  => {
    if(isNullOrUndefined(t(value))) {
      return message;
    }
    return true;
  });


  const getSupervisorCyberKeyInFinalFormat = (supervisorCyberKey: 0 | 1 | 2 | 3) => ({
      0: 1,
      1: 1,
      2: 0,
      3: 0,
    }[supervisorCyberKey]
  );

  const yesNo = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { role } = useRole();
  const isKawiButtonVisible = role !== Roles.ONS;

  const isVerianFieldVisible = status !== Status.Coding;

  const isSupervisorVisible = status === Status.Supervisor;

  const isONSVisible =
    status === Status.ONSReview ||
    status === Status.ONSProCoding ||
    status === Status.ONSCoding ||
    status === Status.ExecReview ||
    status === Status.Review;

  const isFinalCodeVisible = status === Status.ExecReviewCyber || status === Status.ExecReview;

  const isPushToONSVisible = status === Status.ExecReview;

  const isProvisionalVisible = status === Status.ONSCoding;

  const isAcceptONSVisible = status === Status.ExecReview || status === Status.Review;

  const showDialog = status !== Status.Coding && status !== Status.ONSCoding;

  const isUncertainVisible = status === Status.Supervisor;

  const isCoderFinalVisible = status !== Status.Supervisor && status !== Status.Coding;

  const isCyberVisible = status === Status.Supervisor || status === Status.ExecReviewCyber;

  const data = useWatch({
    control,
    name: `respondents.${index}`,
  });

  const getVerianSelectSize = (): number => {
    if ((isUncertainVisible && isCyberVisible) || (isCyberVisible && isCoderFinalVisible)) {
      return 7;
    }
    if (isUncertainVisible || isCyberVisible || isCoderFinalVisible) {
      return 8;
    }
    return 11;
  };

  const commentValidation = (value: any): IValidation => {
    const supervisorCyberKey = data?.TnsCyberCertain;
    const finalCyberKey = data?.Final_Cyber_Flag;

    if ((status === Status.ExecReviewCyber) 
      && (!isNullOrUndefined(finalCyberKey) && !isNullOrUndefined(supervisorCyberKey) && !value) 
      && (Number(finalCyberKey) !== getSupervisorCyberKeyInFinalFormat(supervisorCyberKey))) {
        return 'You must add a comment as you have modified the cyber code';
      }

    if ((status === Status.Supervisor)
      && t(data.TNS_Super_Code) !== t(data.TNS_Coder_Code)
      && t(value) === null
    ) {
      return "You must add a comment as your code differs from the original coder's";
    }

    if ((status === Status.Review)
      && t(data.TNS_Accept_ONS_Code) === false 
      && t(value) === null
    ) {
      return "You must add a comment as you have rejected the ONS's code";
    }
    if ((status === Status.ONSReview) && (t(value) === null)) {
      return 'You must enter a comment in response to Verian';
    }

    if ((status === Status.ONSCoding || status === Status.ONSProCoding)
      && t(data.ONS_Code) !== t(data.TNS_Code)
      && t(value) === null
    ) {
      return "You must add a comment as your code differs from Verian's";
    } 

    return true;
  };

  const acceptONSValidation = (value: string | null): IValidation => {
    if ((status === Status.Review)) {
      if ((t(value) === null)) {
        return "You must accept or reject the ONS's code";
      }
      return true;
    }
    // deliberately return undefined. In Ivalidation it means, that field is disabled.
    return undefined;
  };

  const ONSCodeIsProvisionalValidation = (value: string | null) => {
    if (isProvisionalVisible && (t(value) === null)) {
      return 'You must select the provisional flag';
    }
    return true;
    
  }
  const coderFinalValidation = (value: any): IValidation => {
    if (status === Status.ExecReviewCyber) {
      if (t(value) === null) {
        return 'You must set the final cyber flag';
      }
      return true;
    }
    // deliberately return undefined. In Ivalidation it means, that field is disabled.
    return undefined;
  };
  const codeONSValidation = (value: any): IValidation => {
    if (status === Status.ONSCoding || status === Status.ONSProCoding) {
      if ((t(value) === null)) {
        return 'You must select a code';
      } 
      return true;
    }
    // deliberately return undefined. In Ivalidation it means, that field is disabled.
    return undefined;
  };
  const isFinalCodeDisabled = (): IValidation => {
    if (status === Status.ExecReviewCyber) {
      return t(data.Final_Code) !== null;
    }
  };
  const finalCodeValidation = (value: any): IValidation => {
    if ((status === Status.ExecReview)
      && t(value) === null
      && t(data.Push_To_ONS) !== true
    ) {
      return 'You must either set the final code or push to the ONS';
    }
    return true;
  };
  const pushToONSValidation = (value: any): IValidation => {
    if ((status === Status.ExecReview)
      && t(data.Final_Code) !== null
      && t(value) === true
    ) {
      return 'If you set the final code, you cannot also push it to the ONS';
    }
    return true;
  };

  const handleOpenCawi = (): void => {
    window.open(field.CAWILink+"&uid="+username, '_blank', 'location=yes,scrollbars=yes,status=yes');
  };

  const handleOpenForm = (): void => {
    setIsPopupOpen(true);
  };

  return (
    <>
      <Form.Group as={Row} className="mb-3 csew-form-group">
        <Col>
          <button onClick={handleOpenForm} type="button" className="csew-button csew-button_small">
            Open Form
          </button>
          {isKawiButtonVisible && (
            <button onClick={handleOpenCawi} type="button" className="csew-margin-left csew-button csew-button_secondary csew-button_small">
              Start CAWI
            </button>
          )}
        </Col>
        <FormInput size={1} {...props} name="Form_Classification" label="Type" disabled={true} />
      </Form.Group>

      <Form.Group as={Row} className="mb-3 csew-form-group">
        <FormInput size={2} {...props} name="Serial_Num" label="Serial" disabled={true} />
        <FormInput {...props} name="Offence_Snippet" label="Description" disabled={true} />
      </Form.Group>
      {isVerianFieldVisible && (
        <Form.Group as={Row} className="mb-3 csew-form-group">
          <FormSelect
            // disabled attribute should be added conditionally. bug 84661
            {...(isFormReadOnly ? { disabled: true } : {} )}
            size={getVerianSelectSize()}
            {...props}
            name="TNS_Code"
            label={status === Status.Supervisor ? 'Coder' : 'Verian'}
            options={codeData}
          />
          {isUncertainVisible && (
          <FormSelect 
            {...(isFormReadOnly ? { disabled: true } : {} )} 
            {...props} 
            name="TNS_Coder_Is_Uncertain"
            options={certain} 
           />)}
          {isCyberVisible && (
          <FormSelect 
            {...props} 
            {...(isFormReadOnly ? { disabled: true } : {} )} 
            name="TnsCyberCertain" 
            options={cyberSupervisorValues} 
          />)}
          {isCoderFinalVisible && (
            <FormSelect
              {...props} 
              {...(isFormReadOnly ? { disabled: true } : {} )} 
              name="Final_Cyber_Flag" 
              label="Final" 
              options={cyberFinalValues} 
              validate={modifyValidationFunction(coderFinalValidation, isFormReadOnly)} 
            />
          )}
        </Form.Group>
      )}
      {isSupervisorVisible && (
        <Form.Group as={Row} className="mb-3 csew-form-group">
          <FormSelect
            size={7}
            {...props}
            name="TNS_Super_Code"
            label="Supervisor"
            options={codeData}
            {...(isFormReadOnly ? { disabled: true } : {} )}
            validate={modifyValidationFunction(
              createValidationOnValueExists('You must select an offence code and whether you are certain or uncertain of it'), 
              isFormReadOnly
            )}
          />
          <FormSelect  
            {...(isFormReadOnly ? { disabled: true } : {} )} 
            {...props} 
            name="TNS_Super_Is_Uncertain" 
            options={certain} 
            validate={modifyValidationFunction(
              createValidationOnValueExists('You must select certain or uncertain of it'), 
              isFormReadOnly
            )}
          />
          <FormSelect
            {...props}
            {...(isFormReadOnly ? { disabled: true } : {} )}
            name="TnsSuperCyberCertain"
            options={cyberSupervisorValues}
            validate={modifyValidationFunction(
              createValidationOnValueExists('You must select a cyber flag and whether you are certain or uncertain of it'), 
              isFormReadOnly
            )}
          />
        </Form.Group>
      )}
      {isONSVisible && (
        <Form.Group as={Row} className="mb-3 csew-form-group">
          <FormSelect
            size={8}
            {...props}
            {...(isFormReadOnly ? { disabled: true } : {} )}
            name={status === Status.ONSProCoding ? 'ONS_Code' : 'ONS_Code'}
            label="ONS"
            options={codeData}
            validate={modifyValidationFunction(codeONSValidation, isFormReadOnly)}
            defaultValue={field.TNS_Code}
          />
          {isProvisionalVisible && (
            <FormSelect 
              {...props} 
              {...(isFormReadOnly ? { disabled: true } : {} )} 
              name="ONS_Code_Is_Provisional" 
              label="Mark as provisional" 
              options={yesNo} 
              validate={modifyValidationFunction(ONSCodeIsProvisionalValidation, isFormReadOnly)} 
            />
          )}
          {isAcceptONSVisible && (
            <FormSelect 
              {...props} 
              {...(isFormReadOnly ? { disabled: true } : {} )} 
              name="TNS_Accept_ONS_Code" 
              label="Verian Accept" 
              options={yesNo} 
              validate={modifyValidationFunction(acceptONSValidation, isFormReadOnly)} 
            />
          )}
        </Form.Group>
      )}
      {isFinalCodeVisible && (
        <Form.Group as={Row} className="mb-3 csew-form-group">
          <FormSelect
            size={isPushToONSVisible ? 8 : 11}
            {...props}
            name="Final_Code"
            label="Final"
            options={codeData}
            disabled={isFormReadOnly || isFinalCodeDisabled()}
            validate={modifyValidationFunction(finalCodeValidation, isFormReadOnly)}
          />
          {isPushToONSVisible && (
            <FormSelect 
              {...props} 
              {...(isFormReadOnly ? { disabled: true } : {} )} 
              name="Push_To_ONS" 
              label="Push to ONS" 
              options={yesNo} 
              validate={modifyValidationFunction(pushToONSValidation, isFormReadOnly)} 
              hasNoEmptyOption 
            />
          )}
        </Form.Group>
      )}
      <Form.Group as={Row} className="mb-3 csew-form-group">
        <FormTextArea 
          {...props} 
          {...(isFormReadOnly ? { disabled: true } : {} )} 
          name="Comment" 
          label="Comments" 
          showDialog={showDialog} 
          validate={modifyValidationFunction(commentValidation, isFormReadOnly)} 
        />
      </Form.Group>
      {isPopupOpen && (
        <NewWindow onClose={() => setIsPopupOpen(false)}>
          <VictimForm data={field} />
        </NewWindow>
      )}
    </>
  );
};
