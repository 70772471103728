import { ReactElement } from 'react';
import { useMsal } from '@azure/msal-react';

export const SignOutButton = (): ReactElement => {
  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };
  return (
    <button className="ml-auto csew-button" onClick={handleLogout}>
      Sign out
    </button>
  );
};
