import { FC, ReactNode, useState, useMemo } from 'react';
import { Toast } from 'react-bootstrap';
import NotificationsContext from './NotificationsContext';

interface Notification {
  id: string;
  header: string;
  text: string;
  variant?: string;
}

interface NotificationsProviderProps {
  children: ReactNode;
}

interface NotificationsContextType {
  notifications: Notification[];
  showNotification: (header: string, text: string, variant?: string) => void;
}

const NotificationsProvider: FC<NotificationsProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const showNotification = (header: string, text: string, variant?: string) => {
    const id = Math.random().toString(36).substr(2, 9);
    setNotifications((prev) => [...prev, { id, header, text, variant }]);
  };

  const removeNotification = (id: string) => {
    setNotifications((prev) => prev.filter(notification => notification.id !== id));
  };

  const value: NotificationsContextType = useMemo(() => ({ notifications, showNotification }), [notifications]);

  return (
    <NotificationsContext.Provider value={value}>
      {children}
      <div style={{ position: 'fixed', top: 60, right: 20, zIndex: 9999 }}>
        {notifications.map((notification) => (
          <Toast key={notification.id} onClose={() => removeNotification(notification.id)} delay={8000} autohide style={{ marginTop: '30px'}} bg={notification.variant}>
            <Toast.Header style={{ justifyContent: 'space-between' }}>
              <strong className="mr-auto">{notification.header}</strong>
            </Toast.Header>
            <Toast.Body>{notification.text}</Toast.Body>
          </Toast>
        ))}
      </div>
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
