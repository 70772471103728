import { useGetVictimFormQuery } from '../../store/apis';
import { Card, Table } from 'react-bootstrap';
import { Loader } from '../Loader';
import { ReactElement } from 'react';
import { IRespondent, IVictimForm } from '../../types';
import { getSurveyTypeLabel, getSurveyTypeShortName, getErrorStatus, notificationMessages } from '../../helpers';
import { useNotificationOnErrorFromServer } from '../../hooks';

interface IProps {
  data: IRespondent;
}
export const VictimForm = ({ data }: IProps): ReactElement => {
  const { data: dataRes, isLoading, error } = useGetVictimFormQuery(`${data.RespDbID}/${data.Form_Num}/${data.Survey_Type}`);
  const victimData: IVictimForm = dataRes;

  useNotificationOnErrorFromServer(error, 401, 
    notificationMessages.unauthorized.header, 
    notificationMessages.unauthorized.message);

  if (isLoading) {
    return <Loader />;
  }
  if (error && getErrorStatus(error) === 401) {
    return <div>You do not have permission to view this page.</div>;
  }

  if (!victimData) {
    return <>No data</>;
  }

  const { SerialNum, FormNum, SurveyType, SurveyDesc, Questions } = victimData;
  const SerialNumWithoutFormNum = String(SerialNum).slice(0, -2);
  const title = 
    `${SerialNumWithoutFormNum} (${FormNum}) - ${getSurveyTypeLabel(SurveyType)} ${getSurveyTypeShortName(SurveyType)}`;
  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td className="fw-bold">Cserial</td>
                <td>
                  {`${SerialNumWithoutFormNum}(${FormNum})`}
                </td>
              </tr>
              <tr style={{ borderBottomWidth: 3, borderBottomColor: 'black' }}>
                <td className="fw-bold">SurveyDesc</td>
                <td>{SurveyDesc}</td>
              </tr>
              {Questions ? (
                Questions.map((item: any) => {
                  return (
                    <tr key={item.QuestionId}>
                      <td>
                        <span className="fw-bold">{item.QuestionId}:</span> {item.QuestionLabel}
                      </td>
                          <td>{item?.Answers?.map((item:any) => item.AnswerLabel).join(", ")}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={2}> Zero records</td>
                </tr>
              )}
            </tbody>
          </Table>
      </Card.Body>
    </Card>
  );
};
