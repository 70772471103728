import { Col, Form } from 'react-bootstrap';
import { useController, useWatch } from 'react-hook-form';
import { IFormSelect } from '../../types';
import { ReactElement } from 'react';
import { t } from '../../helpers';

export const FormSelect = ({
  label,
  size,
  disabled,
  options,
  control,
  index,
  name,
  validate,
  message,
  defaultValue,
  hasNoEmptyOption,
}: IFormSelect): ReactElement => {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({
    name: `respondents.${index}.${name}`,
    control,
    rules: validate ? { validate } : message ? { validate: (value) => t(value) !== null || message } : {},
  });

  const value = useWatch({
    control,
    name: `respondents.${index}.${name}`,
  });

  const hasValidation = (validate && validate(value) !== undefined) || message;
  const isDisabled = disabled === undefined ? !hasValidation : disabled;

  return (
    <>
      {label && (
        <Form.Label column sm={label.length < 11 ? 1 : 2} className="csew-text-align-right">
          {label}
        </Form.Label>
      )}
      <Col sm={size} className="position-relative">
        <Form.Select disabled={isDisabled} {...field} isInvalid={invalid}>
          {hasNoEmptyOption || <option value={'null'}>Select...</option>}
          {options.map((option: any) => (
            <option value={option.value} key={Math.random().toString()}>
              {option.label}
            </option>
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid" tooltip>
          {error?.message}
        </Form.Control.Feedback>
      </Col>
    </>
  );
};
