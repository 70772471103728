import { useEffect, useContext } from 'react';
import { useGetUserQuery } from './store/apis';
import NotificationsContext from './components/notificationsProvider/NotificationsContext';
import { getErrorStatus } from './helpers';

export const useRole = () => {
  const { data, isLoading } = useGetUserQuery();
  const role = data?.Roles?.[0]?.User_Role_ID;
  return {role, isRoleLoading: isLoading};
}

export const useNotificationOnErrorFromServer = (error: any, errorStatusToHandle: number, header: string, message: string, variant: string='danger') => {
  const { showNotification } = useContext(NotificationsContext);
  const errorStatus = getErrorStatus(error)

  useEffect(() => {
    if (errorStatus === errorStatusToHandle) {
      showNotification(header, message, variant);
    }
  }, [errorStatus]);
}