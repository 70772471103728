import { Configuration, PopupRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_AUTHORUTY,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
  },
  system: {
    // loggerOptions: {
    //   loggerCallback: (level, message, containsPII) => {
    //     console.log(message);
    //   },
    //   logLevel: LogLevel.Verbose,
    // },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: [process.env.REACT_APP_SCOPES ?? ''],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const profileLink = 'https://myaccount.microsoft.com/?ref=MeControl&login_hint=';
