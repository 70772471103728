import { ReactElement } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useController } from 'react-hook-form';

export const Date = (props: any): ReactElement => {
  const { field } = useController(props);

  return (
    <Form.Group className="mb-3 csew-form-group">
      <Form.Label>{props.label}</Form.Label>

      <InputGroup className="csew-date">
        <InputGroup.Text id="basic-addon1">{props.text}</InputGroup.Text>
        <Form.Control {...field} type="month" max={props.max} min={props.min}></Form.Control>
      </InputGroup>
    </Form.Group>
  );
};
