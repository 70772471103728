import { ReactElement } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config';

export const SignInButton = (): ReactElement => {
  const { instance } = useMsal();
  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <button className="ml-auto csew-button" onClick={handleLogin}>
      Please, Sign in
      <div></div>
    </button>
  );
};
