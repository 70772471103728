import { useState, ReactElement } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import '../../css/Dashboard.css';
import { DashboardListItem } from './DashboardListItem';
import { IInnerRecord } from '../../types';

export const DashboardList = ({ data, role }: any): ReactElement => {
  const [open, setOpen] = useState(true);

  return (
    <ListGroup.Item>
      <Row onClick={() => setOpen(!open)} className="csew-pointer">
        <Col xs={8}>
          {open ? <ChevronUp /> : <ChevronDown />}
          <span className="csew-margin-left">{`${data?.type} - ${data.month}`}</span>
        </Col>
        <Col className="csew-text-align-right" xs={4}>
          {data.completedQuantity} complete forms
        </Col>
      </Row>
      <Collapse in={open} className='csew-collapse'>
        <div className="csew-items-margin-top">
          <ProgressBar striped now={data.progress} className="csew-progress"/>
          <div>
            <Row>
              <Col>
                <Card className='csew-card'>
                  <ListGroup variant="flush">
                    {data.verianQueue.map((item: IInnerRecord, key: number) => (
                      <DashboardListItem key={key} data={item} role={role} />
                    ))}
                  </ListGroup>
                </Card>
              </Col>
              <Col>
                <Card className='csew-card'>
                  <ListGroup variant="flush">
                    {data.onsQueue.map((item: IInnerRecord, key: number) => (
                      <DashboardListItem key={key} data={item} role={role} />
                    ))}
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Collapse>
    </ListGroup.Item>
  );
};
