import { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { Controller } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useGetReportListQuery } from '../../store/apis';
import { Loader } from '../Loader';
import { onSubmit } from './helpers';
import { getErrorStatus, notificationMessages } from '../../helpers';
import { useNotificationOnErrorFromServer } from '../../hooks';

interface reportType {
  ReportType: string;
  ReportTitle: string;
  ReportId: number | string;
}

export const ReferralsReport = (): ReactElement => {
  const { control, handleSubmit } = useForm();
  const { data: reportTypeData, isLoading, error } = useGetReportListQuery();
    const defaultReportType = reportTypeData ? reportTypeData[0] : null;
    const defaultReportValue = defaultReportType != null ? `${defaultReportType.ReportType}/${defaultReportType.ReportId}` : "";
  useNotificationOnErrorFromServer(error, 401, 
    notificationMessages.unauthorized.header, 
    notificationMessages.unauthorized.message);

  if (isLoading) {
    return <Loader />
  }

  if (error && getErrorStatus(error) === 401) {
    return <>You do not have permission to view this page.</>;
  }

  if (!defaultReportType) {
    return <>No report-type data</>;
  }

  return (
    <div className='mt-3'>
      <h4>Download report</h4>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-3'>
          <div className='mb-1'><label>Report type</label></div>
          <Controller
            defaultValue={defaultReportValue}
            name="reportTypeAndId"
            control={control}
            render={({ field }) => (
              <Form.Select {...field}>
                {reportTypeData.map((item: reportType) => {
                  const {
                    ReportType: reportType, 
                    ReportTitle: title,
                    ReportId: id
                  } = item;
                  return (
                    <option key={reportType} value={`${reportType}/${id}`}>
                      {title}
                    </option>
                  )
                })}
              </Form.Select>
            )}
          />
        </div>
        <div className='mb-3'>
          <div className='mb-1'><label>from</label></div>
          <Controller
            name="from"
            control={control}
            defaultValue={new Date()}
            render={({ field }) => (
              <ReactDatePicker
                {...field}
                selected={field.value}
                onChange={(date: any) => field.onChange(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            )}
          />
        </div>
        <div className='mb-3'>
          <div className='mb-1'><label>to</label></div>
          <Controller
            name="to"
            control={control}
            defaultValue={new Date()}
            render={({ field }) => (
              <ReactDatePicker
                {...field}
                selected={field.value}
                onChange={(date: any) => field.onChange(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            )}
          />
        </div>
        <div className='mb-3'>
          <div className='mb-1'><label>Survey type</label></div>
          <Controller
            defaultValue="A"
            name="surveyType"
            control={control}
            render={({ field }) => (
              <Form.Select {...field}>
                <option value={'A'}>Adult</option>
                <option value={'C'}>Child</option>
                <option value={'F'}>Fraud</option>
              </Form.Select>
            )}
          />
        </div>
        <button className="csew-margin-left csew-button csew-button_small" type="submit">
            Download report
        </button>
      </Form>
    </div>
  );
};
