import { ReactElement } from 'react';
import { DetailsTable } from '../components/details/DetailsTable';
import { useLocation } from 'react-router-dom';
import { useGetDetailsQuery } from '../store/apis';
import { Loader } from '../components/Loader';

export const DetailsPage = (): ReactElement => {
  const location = useLocation();

  const { data, isLoading } = useGetDetailsQuery(location.pathname);
  if (isLoading) {
    return <Loader />;
  }
  if (!data) {
    return <>No data</>;
  }
  return <DetailsTable data={data} />;
};
