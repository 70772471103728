import { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { Status } from '../../enums';

export const CodingFormTitle = ({ status }: any): ReactElement => {
  switch (status) {
    case Status.Supervisor:
      return (
        <Form.Text>
          Verian Group Coders are uncertain about the offence code and/or cyber flag.
          <br />
          For each affected form, please select an offence code and the appropriate cyber flag, and indicate whether or not you are certain
          of these values.
          <p />
          When you have finished editing, click the &#39;Save&#39; button.
          <br />
          To view details of the offence, click the &#39;Open Form&#39; button to view the victim form in full.
        </Form.Text>
      );
    case Status.Review:
      return (
        <Form.Text>
          Verian Group and the ONS disagree about the code for one or more of these victim forms.
          <br />
          If you are happy to accept the ONS&#39;s code, click the &#39;Verian Accept&#39; option &#39;Yes&#39;: otherwise click the
          &#39;Verian Accept&#39; option &#39;No&#39;.
          <br />
          Please add a comment if you reject the ONS&#39;s code, and it will be referred to the Verian Group exec team for further review.
          <p />
          When you have finished editing, click the &#39;Save&#39; button.
          <br />
          To view details of the offence, click the &#39;Open Form&#39; button to view the victim form in full.
        </Form.Text>
      );

    case Status.ExecReview:
      return (
        <Form.Text>
          Verian Group coders have rejected the ONS&#39;s code for one or more of these victim forms.x
          <br />
          Please select the final code for each of the rejected victim forms, or click the box to push the record back to the ONS for
          review.
          <br />
          Please add a comment, regardless of what you do.
          <p />
          When you have finished editing, click the &#39;Save&#39; button.
          <br />
          To view details of the offence, click the &#39;Open Form&#39; button to view the victim form in full.
        </Form.Text>
      );
    case Status.ExecReviewCyber:
      return (
        <Form.Text>
          Verian Group Coders are uncertain about the cyber flag.
          <br />
          Please select the final cyber flag for each victim form where it is missing.
          <br />
          Optionally also set the final offence code if you disagree with the Supervisor&#39;s code.
          <p />
          When you have finished editing, click the &#39;Save&#39; button.
          <br />
          To view details of the offence, click the &#39;Open Form&#39; button to view the victim form in full.
        </Form.Text>
      );
    case Status.ONSCoding:
      return (
        <Form.Text>
          Select a code for each victim form.
          <br />
          If you disagree with Verian Group&#39;s code, please also add a comment as these forms will be referred back to Verian Group for
          review.
          <br />
          If you are provisionally coding, please tick the &#39;mark as provisional&#39; box - this means that your codes will need to be
          confirmed before they are considered final
          <br />
          When you have finished editing, click the &#39;Save&#39; button.
          <br />
          To view details of the offence, click the &#39;Open Form&#39; button to view the victim form in full.
        </Form.Text>
      );
    case Status.ONSProCoding:
      return (
        <Form.Text>
          Check you are happy with the code you have given each victim form and change any as required.
          <p />
          When you have finished editing, click the &#39;Save&#39; button.
          <br />
          The codes will then be considered confirmed.
          <br />
          To view details of the offence, click the &#39;Open Form&#39; button to view the victim form in full.
        </Form.Text>
      );
    case Status.ONSReview:
      return (
        <Form.Text>
          The ONS and Verian Group disagree about the code for one or more of these victim forms.
          <br />
          For each affected form, please add a comment to further explain your reasoning and respond to any questions Verian Group has
          noted.
          <p />
          When you have finished editing, click the &#39;Save&#39; button.
          <br />
          The codes will then be considered confirmed.
          <br />
          To view details of the offence, click the &#39;Open Form&#39; button to view the victim form in full.
        </Form.Text>
      );
    default:
      return <></>;
  }
};
