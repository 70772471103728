import { ReactElement } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { profileLink } from '../config';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
import { LinkContainer } from 'react-router-bootstrap';
import { Container, Form, Nav, Navbar } from 'react-bootstrap';
import { Loader } from './Loader';
import { Roles } from '../enums';
import { useRole } from '../hooks';

export const MainMenu = (): ReactElement => {
  const { accounts } = useMsal();
  const { role, isRoleLoading } = useRole();

  const isDownloadVisible = role !== Roles.Coder;

  const name = accounts[0]?.name;
  const username = accounts[0]?.username;
  if (isRoleLoading) {
    return <Loader />;
  }
  return (
    <Navbar expand="lg" className="csew-grey-brand-color csew-navbar">
      <Container>
        <AuthenticatedTemplate>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              {isDownloadVisible && (
                <LinkContainer to="/download">
                  <Nav.Link>Download</Nav.Link>
                </LinkContainer>
              )}
              <LinkContainer to="/records">
                <Nav.Link>Reports</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className="csew-user">
              <Nav.Link href={profileLink + username} target={'_blank'}>
                {name}
              </Nav.Link>
              <Form>
                <SignOutButton />
              </Form>
            </Nav>
          </Navbar.Collapse>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <SignInButton />
        </UnauthenticatedTemplate>
      </Container>
    </Navbar>
  );
};
