//  value converter to right type
import { Roles, Status } from './enums';
import { IInnerRecord } from './types';
import { FetchResponseWithError } from './types';

export const isNullOrUndefined = (value: any): boolean => (
  value === null || value === undefined
);

export const t = (value: any): any => {
  switch (value) {
    case 'null':
      return null;
    case null:
      return null;
    case '':
      return null;
    case 'true':
      return true;
    case true:
      return true;
    case 'false':
      return false;
    case false:
      return false;
    case 'undefined':
      return undefined;
    case undefined:
      return undefined;
  }
  if (!isNaN(+value)) {
    return +value;
  }
  return value;
};

export const getSubRolesByRole = (role: string): any => {
  switch (role) {
    case Roles.Exec:
      return [Status.ExecReview, Status.ExecReviewCyber];
    case Roles.Coder:
      return [Status.Coding];
    case Roles.Supervisor:
      return [Status.Coding, Status.Supervisor, Status.Review];
    case Roles.ONS:
      return [Status.ONSReview, Status.ONSCoding, Status.ONSProCoding];
    default:
      return [];
  }
};

export const getAccessSubRolesByRole = (role: string): any => {
  switch (role) {
    case Roles.Exec:
      return [
        Status.Coding,
        Status.Supervisor,
        Status.Review,
        Status.ONSReview,
        Status.ONSCoding,
        Status.ONSProCoding,
        Status.ExecReview,
        Status.ExecReviewCyber,
      ];
    case Roles.Coder:
      return [Status.Coding];
    case Roles.Supervisor:
      return [
        Status.Coding,
        Status.Supervisor,
        Status.Review,
        Status.ONSReview,
        Status.ONSCoding,
        Status.ONSProCoding,
        Status.ExecReview,
        Status.ExecReviewCyber,
      ];
    case Roles.ONS:
      return [
        Status.Coding,
        Status.Supervisor,
        Status.Review,
        Status.ONSReview,
        Status.ONSCoding,
        Status.ONSProCoding,
        Status.ExecReview,
        Status.ExecReviewCyber,
      ];
    default:
      return [];
  }
};

export const getStatusString = (status: string, param1: number = 0, param2: number = 0): string => {
  switch (status) {
    case Status.Coding:
      return `${param1} forms to code, ${param2} respondents`;
    case Status.Review:
      return `${param1} forms from ONS for Supervisor to review`;
    case Status.Supervisor:
      return `${param1} forms for Supervisors to review`;
    case Status.ExecReviewCyber:
      return `${param1} cyber flags for Execs to review`;
    case Status.ExecReview:
      return `${param1} forms for Execs to review`;
    case Status.ONSCoding:
      return `${param1} quality checks / uncertains / referrals`;
    case Status.ONSProCoding:
      return `${param1} forms with provisional codes to confirm`;
    case Status.ONSReview:
      return `${param1} forms to review`;
    default:
      return '';
  }
};
export const getPrefix = (item: IInnerRecord): string => {
  if (
    item.Survey_Type_Group_Label === 'Adult' &&
    (item.Status_ID === Status.ONSCoding || item.Status_ID === Status.Coding || item.Status_ID === Status.Supervisor)
  ) {
    return item.Survey_Type_Grouping === 'F' ? 'FRD ' : 'TRD ';
  }
  return '';
};
export const getSurveyTypeLabel = (id: string | undefined): string => {
  switch (id) {
    case 'A':
      return 'Adult';
    case 'C':
      return 'Child';
    case 'F':
      return 'Fraud';
    default:
      return '';
  }
};

export const getSurveyTypeShortName = (id: string | undefined): string => {
  switch (id) {
    case 'A':
      return 'TRD';
    case 'F':
      return 'FRD';
    default:
      return '';
  }
};
export const getCodingStatusLabel = (status: string | undefined): string => {
  switch (status) {
    case Status.Coding:
      return 'Verian Coding';
    case Status.Review:
      return 'Verian Review';
    case Status.Supervisor:
      return 'Verian Supervisor Coding';
    case Status.ExecReviewCyber:
      return 'Verian Exec Cyber Flag Review';
    case Status.ExecReview:
      return 'Verian Exec Review';
    case Status.ONSCoding:
      return 'ONS Coding';
    case Status.ONSProCoding:
      return 'ONS Provisional Coding';
    case Status.ONSReview:
      return 'ONS Review';
    default:
      return '';
  }
};

export const codingStatuses = [
  {
    statusId: 'T_CDE',
    label: '{0} forms to code, {1} respondents',
  },
  {
    statusId: 'T_REV',
    label: '{0} forms from ONS for Supervisor to review',
  },
  {
    statusId: 'T_SCD',
    label: '{0} forms for Supervisors to review',
  },
  {
    statusId: 'T_ECY',
    label: '{0} cyber flags for Execs to review',
  },
  {
    statusId: 'T_EXC',
    label: '{0} forms for Execs to review',
  },
  {
    statusId: 'O_CDE',
    label: '{0} quality checks / uncertains / referrals',
  },
  {
    statusId: 'O_CNF',
    label: '{0} forms with provisional codes to confirm',
  },
  {
    statusId: 'O_REV',
    label: '{0} forms to review',
  },
];

export const normalizeCodingFormData = (formData: any, role: Roles, status?: string ) => {
  const normalizedFormData: any = {};
  Object.keys(formData).forEach((property) => {
    normalizedFormData[property] = t(formData[property]);
  });

  if (role === Roles.ONS 
    && (status === Status.ONSProCoding || status === Status.ONSReview) 
    && normalizedFormData?.ONS_Code_Is_Provisional === null) {
    normalizedFormData.ONS_Code_Is_Provisional = false;
  }
  return normalizedFormData;
}

export const checkResponseOnError = (response: FetchResponseWithError, functionName?: string) => {
  if ('error' in response && response.error) {
    const respError =response.error;
    if ('originalStatus' in respError 
      && 'error' in respError 
      && 'data' in respError 
      && respError.originalStatus !== 200) {
      const { originalStatus, error, data } = respError;
      throw new Error(`${functionName || 'data'} call returned with error status ${originalStatus} and message: ${data}; ${error}`);
    }
  }
}

export const getErrorStatus = (error: any) => {
  const isErrorObject = error && typeof error === 'object';
  const originalErrorStatus = (isErrorObject && ('originalStatus' in error)) ? error.originalStatus : undefined;
  const errorStatus = (isErrorObject && ('status' in error)) ? error.status : undefined;
  return originalErrorStatus || errorStatus;
}

export const notificationMessages = {
  unauthorized: {
    header: 'Unauthorised access',
    message: 'You do not have permission to view this page',
  },
  failedSubmit: {
    header: 'Submit failed',
    message: 'You do not have permission to update this form',
  }
}
