import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Search } from 'react-bootstrap-icons';
import '../../css/Dashboard.css';
import { getAccessSubRolesByRole, getPrefix, getStatusString, getSubRolesByRole } from '../../helpers';
import { IInnerRecord } from '../../types';
import { Roles, Status } from '../../enums';

interface IProps {
  data: IInnerRecord;
  role: any;
}
export const DashboardListItem = ({ data, role }: IProps): ReactElement => {
  const isLinkActive = data.Num_Resp_Forms > 0 && getSubRolesByRole(role).includes(data.Status_ID);
  const isDetailsActive = data.Num_Resp_Forms > 0 && getAccessSubRolesByRole(role).includes(data.Status_ID);

  const row: string = getPrefix(data) + getStatusString(data.Status_ID, data.Num_Resp_Forms, data.Num_Resps);

  if (role === Roles.ONS && data.Status_ID === Status.Supervisor) {
    return <></>;
  }

  return (
    <ListGroup.Item>
      {isLinkActive ? (
        <Link to={`respondents/coding/${data.Status_ID}/${data.Wave_Year}/${data.Wave_Month}/${data.Survey_Type_Grouping}`}>{row}</Link>
      ) : (
        <span>{row}</span>
      )}
      {isDetailsActive && (
        <Link to={`view-tasks-details/${data.Status_ID}/${data.Wave_Year}/${data.Wave_Month}/${data.Survey_Type_Grouping}`}>
          <Search className="search-icon csew-margin-left" />
        </Link>
      )}
    </ListGroup.Item>
  );
};
