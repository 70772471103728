import { ReactElement, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useController } from 'react-hook-form';

export const File = (props: any): ReactElement => {
  const [value, setValue] = useState('');
  const [files, setFiles]: any = useState();
  const [isError, setIsError] = useState(props.isError);
  const invalidType = files && !(files[0]?.name.includes('.xlsx') || files[0]?.name.includes('.xls'));
  const invalidSize = files && !(files[0]?.size < 102400);

  const validateFile = (): string | undefined => {
    if (invalidType) {
      return 'Incorrect file format. Please select an Excel file (.xlsx)';
    }
    if (invalidSize) {
      return 'The file is too big to upload. Max size allowed is 100KB';
    }
  };
  const {
    field,
    fieldState: { error, invalid },
  } = useController({
    ...props,
    rules: {
      validate: validateFile,
    },
  });

  return (
    <Form.Group className="mb-3 position-relative csew-form-group">
      <Form.Label>Upload File</Form.Label>
      <Form.Control
        {...field}
        accept=" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        value={value}
        isInvalid={invalid}
        onChange={(e: any) => {
          setValue(e.target.value);
          setFiles(e.target.files);
          field.onChange(e.target.files);
          setIsError(false);
        }}
        type="file"
        className="csew-file"
      />
      <Form.Control.Feedback type="invalid" tooltip>
        {error?.message}
      </Form.Control.Feedback>
      {isError && (
        <Form.Control.Feedback type="invalid" tooltip style={{ display: 'block' }}>
          The data structure of the file is incorrect. Please use the Template
        </Form.Control.Feedback>
      )}
      {props?.message && (
        <Form.Control.Feedback type="invalid" tooltip style={{ display: 'block' }}>
          The following Victim Forms were not found: {props?.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
