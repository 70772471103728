import { saveAs } from 'file-saver';
import { fetchWithAuthHeader } from '../../store/helpers';

export const onSubmit = async (dataForm: any) => {
  const { reportTypeAndId, from, to, surveyType,  } = dataForm;
  const [reportType, reportId] = reportTypeAndId.split('/')

  const monthFrom = from.getMonth() + 1;
  const yearFrom = from.getFullYear();
  const monthTo = to.getMonth() + 1;
  const yearTo = to.getFullYear();

  let reportName = 'dynamic';
  
  const url = `${process.env.REACT_APP_API_URL}/download-report/${reportName}/${reportId}/${yearFrom}/${monthFrom}/${yearTo}/${monthTo}/${surveyType}`

  const response = await fetchWithAuthHeader(url);
  const base64DataWithQuots = await response.text();
  const base64Data = base64DataWithQuots.substring(1, base64DataWithQuots.length - 1);
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const dataBlob = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

  saveAs(dataBlob, `report_${Date.now()}.xlsx`);
};
