import { createContext } from 'react';

interface Notification {
  id: string;
  text: string;
  variant?: string;
}

interface NotificationsContextType {
  notifications: Notification[];
  showNotification: (header: string, text: string, variant?: string) => void;
}

const NotificationsContext = createContext<NotificationsContextType>({
  notifications: [],
  showNotification: () => {},
});

export default NotificationsContext;
