import { Container, Row, Spinner } from 'react-bootstrap';
import { ReactElement } from 'react';

export const Loader = (): ReactElement => {
  return (
    <div className="position-fixed top-0 bg-black bg-opacity-50 vh-100 vw-100" style={{ zIndex: 10000, left: 0 }}>
      <Container>
        <Row className="justify-content-md-center align-items-md-center" style={{ height: '80vh' }}>
          <Spinner animation="border" />
        </Row>
      </Container>
    </div>
  );
};
