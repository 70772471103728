import { Container } from 'react-bootstrap';
import { MainMenu } from '../MainMenu';
import { ReactElement } from 'react';

export const Header = (): ReactElement => {
  return (
    <header className="csew-header-full fixed-top">
      <Container>
        <div className="csew-header">
          <div className="csew-logo-name-wrapper">
              <img className="csew-logo-image" src={`${process.env.PUBLIC_URL}/logo_verian.webp`} alt="verian-logo.webp" />
            <h5 className="csew-header__public-notice">
              Offence Coding Portal
            </h5>
          </div>
        </div>
      </Container>
      <MainMenu />
    </header>
  );
};
