import { useGetCodeFramesQuery, usePostRespondentMutation, useSyncVictimFormDataToDimensionsMutation } from '../../store/apis';
import { ListGroup, Form, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';

import '../../css/App.css';
import '../../css/Coding.css';
import { FieldValues, useForm, useFieldArray } from 'react-hook-form';
import { ReactElement, useEffect, useState } from 'react';
import { CodingForm } from './CodingForm';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Loader } from '../Loader';
import { CodingFormTitle } from './CodingFormTitle';
import { Status } from '../../enums';
import { checkResponseOnError, normalizeCodingFormData, notificationMessages, getSurveyTypeShortName, getStatusString } from '../../helpers';
import { LinkContainer } from 'react-router-bootstrap';
import { useRole, useNotificationOnErrorFromServer } from '../../hooks';

export const CodingContainer = ({ data, setPath }: any): ReactElement => {
    const surveyType = data[0]?.Survey_Type;
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const { role } = useRole();
    const { statusId, surveyTypeGrouping } = useParams();
    const { data: codeData, isLoading: isCodeLoading } = useGetCodeFramesQuery(surveyType, {skip: !surveyType});
    const { handleSubmit, control, reset } = useForm();
    const { fields } = useFieldArray({
        control,
        name: 'respondents',
    });

    const [postRespondent, { isLoading: isPostLoading, isSuccess: isPostSuccess, error: postError }] = usePostRespondentMutation();
    useNotificationOnErrorFromServer(postError, 400,
        notificationMessages.failedSubmit.header, 
        notificationMessages.failedSubmit.message);

    const [syncVictimFormDataToDimensions] = useSyncVictimFormDataToDimensionsMutation();
    const isCounterVisible = statusId === Status.Coding;

    useEffect(() => {
        reset({ respondents: data });
    }, [data]);

    useEffect(() => {
        if (data.length === 0) {
            navigate('/');
        }
    }, [isPostSuccess, data]);

    const handleSkip = async () => {
        if (data.length === 0) {
            navigate('/');
        } else {
            setPath(`${location.pathname}/${data[0]?.RespDbID}`)
        }
    };

    const onSubmit = async (dataForm: FieldValues): Promise<void> => {
        setIsLoading(true);
        try {
            const serialNum = data[0]?.Serial_Num;
            const respId = String(serialNum).slice(0, -2);
            const result = dataForm.respondents
                .filter((singleFormData: any) => !singleFormData?.ReadOnly)
                .map((singleFormData: any) => normalizeCodingFormData(singleFormData, role, statusId));
            const postResponse = await postRespondent(result);
            checkResponseOnError(postResponse, 'postRespondent');

            const codingStatusForms = result.filter((form: any) => form.Coding_Status === Status.Coding);
            if (codingStatusForms.length) {
                const syncResponse = await syncVictimFormDataToDimensions(respId);
                checkResponseOnError(syncResponse, 'syncVictimFormDataToDimensions');
            }
            await handleSkip();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    if (isCodeLoading || isPostLoading) {
        return <Loader />;
    }

    return (
        <>
            <Row className="mb-3">
                <CodingFormTitle status={statusId} />
            </Row>

            {isLoading && <Loader />}

            <Form onSubmit={handleSubmit(onSubmit)}>
                <LinkContainer to="/">
                    <button type="button" className="csew-button csew-button_small csew-button_tertiary">Stop Coding</button>
                </LinkContainer>
                <button onClick={handleSkip} type="button" className="csew-margin-left csew-button csew-button_small csew-button_secondary">
                    Skip Record
                </button>
                {data?.[0]?.IsSyncInProgress ? (
                    <OverlayTrigger
                        trigger="hover"
                        overlay={
                            <Tooltip id="tooltip-disabled" >
                                Sync is in progress
                            </Tooltip>
                        }
                    >
                        <button disabled={data?.[0]?.IsSyncInProgress} className="mt-3 csew-button csew-button_small" type="submit">
                            Save Record
                        </button>
                    </OverlayTrigger>)
                    : (
                        <button className="mt-3 csew-button csew-button_small" type="submit">
                            Save Record
                        </button>
                    )}
                {isCounterVisible && (
                    <span className="float-end">
                        { getSurveyTypeShortName(surveyTypeGrouping) } { getStatusString(statusId, data?.[0]?.FormsToCode, data?.[0]?.RespondentsToCode) }
                    </span>
                )}

                {fields.map((field: any, index: number) => {
                    const isFormReadOnly = 'ReadOnly' in field && !!field?.ReadOnly;
                    return (
                        <ListGroup key={field.Serial_Num} className="mt-3 shadow">
                            <ListGroup.Item className={isFormReadOnly ? 'csew-form_disabled' : ''}>
                                <CodingForm key={field.Serial_Num} isFormReadOnly={isFormReadOnly} {...{ control, index, field, codeData }} />
                            </ListGroup.Item>
                        </ListGroup>
                    );
                })}
                {data?.[0]?.IsSyncInProgress ? (
                    <OverlayTrigger
                        trigger="hover"
                        overlay={
                            <Tooltip id="tooltip-disabled" >
                                Sync is in progress
                            </Tooltip>
                        }
                    >
                        <button disabled={data?.[0]?.IsSyncInProgress} className="mt-3 csew-button csew-button_small" type="submit">
                            Save Record
                        </button>
                    </OverlayTrigger>)
                 : (
                    <button className="mt-3 csew-button csew-button_small" type="submit">
                      Save Record
                    </button>
                  )}
            </Form>
        </>
    );
};
