import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import '../../css/Dashboard.css';
import '../../css/App.css';
import { Loader } from '../Loader';
import { useGetDashboardQuery } from '../../store/apis';
import { ReactElement } from 'react';
import { DashboardList } from './DashboardList';
import { codingStatuses, getErrorStatus, notificationMessages } from '../../helpers';
import { useRole, useNotificationOnErrorFromServer } from '../../hooks';

export const Dashboard = (): ReactElement => {
  const sortingOrder = codingStatuses.map((item) => item.statusId);

  const { data, isLoading, error  } = useGetDashboardQuery();
  const { role } = useRole();

  useNotificationOnErrorFromServer(error, 401, 
    notificationMessages.unauthorized.header, 
    notificationMessages.unauthorized.message);

  const reduceDashboardData = (dashboardDataByYears: any): any => {
    const dashboardDataByGroup = dashboardDataByYears?.reduce((acc: any, yearData: any)=>{
      const groups = yearData.records;
      return [...acc, ...groups];
    }, []);

    return dashboardDataByGroup?.map((group: any) => {
      const completedQuantity = group.innerRecords.find((rec: any) => rec.Status_ID === 'COMPL')?.Num_Resp_Forms || 0;

      const totalQuantity = group.innerRecords.reduce((acc: number, item: any) => acc + item.Num_Resp_Forms, 0);

      const progress = totalQuantity === 0 ? 0 : (completedQuantity / totalQuantity) * 100;
      return {
        month: group.innerKey.Wave_Label,
        type: group.innerKey.Survey_Type_Group_Label,
        completedQuantity,
        totalQuantity,
        progress,
        verianQueue: group.innerRecords
          .filter((rec: any) => rec.Status_ID.startsWith('T'))
          .sort((a: any, b: any) => sortingOrder.indexOf(a.Status_ID) - sortingOrder.indexOf(b.Status_ID)),
        onsQueue: group.innerRecords
          .filter((rec: any) => rec.Status_ID.startsWith('O'))
          .sort((a: any, b: any) => sortingOrder.indexOf(a.Status_ID) - sortingOrder.indexOf(b.Status_ID)),
      };
    });
  };

  const dashboardList = reduceDashboardData(data);

  if (isLoading) {
    return <Loader />;
  }

  if (error && getErrorStatus(error) === 401) {
    return <div>You do not have permission to view this page.</div>;
  }

  if (!dashboardList || dashboardList?.length === 0) {
    return <div>No records to display</div>;
  }

  return (
    <ListGroup className="csew-striped-list" style={{ marginTop: '1rem' }}>
      <ListGroup.Item>
        <Row>
          <Col>Verian Queue</Col>
          <Col>ONS Queue</Col>
        </Row>
      </ListGroup.Item>
      {dashboardList?.map((listData: any, key: number) => (
        <DashboardList data={listData} role={role} key={key} />
      ))}
    </ListGroup>
  );
};
