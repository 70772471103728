export enum Status {
  Complete = 'COMPL',
  ONSCoding = 'O_CDE',
  ONSProCoding = 'O_CNF',
  ONSReview = 'O_REV',
  Coding = 'T_CDE',
  ExecReviewCyber = 'T_ECY',
  ExecReview = 'T_EXC',
  Review = 'T_REV',
  Supervisor = 'T_SCD',
}
export enum Roles {
  ONS = 'ONS_C',
  Coder = 'TNS_C',
  Exec = 'TNS_E',
  Supervisor = 'TNS_S',
}
