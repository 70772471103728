import { Routes, Route } from 'react-router-dom';
import { DashboardPage } from './pages/DashboardPage';
import { HomePage } from './pages/HomePage';
import { CodingPage } from './pages/CodingPage';
import { LockedRecords } from './components/records/LockedRecords';
import { DetailsPage } from './pages/DetailsPage';
import { DownloadPage } from './pages/DownloadPage';
import { ReactElement } from 'react';

export const AppRoutes = (): ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/records" element={<LockedRecords />} />
      <Route path="/download" element={<DownloadPage />} />
      <Route path="/respondents/coding/:statusId/:waveYear/:waveMonth/:surveyTypeGrouping" element={<CodingPage />} />
      <Route path="/view-tasks-details/:statusId/:waveYear/:waveMonth/:surveyTypeGrouping" element={<DetailsPage />} />
    </Routes>
  );
};
