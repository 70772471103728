import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { msalInstance } from '../index';
import { loginRequest } from '../config';

export const prepareHeaders = async (headers: Headers): Promise<any> => {
  try {
    const { accessToken } = await msalInstance.acquireTokenSilent(loginRequest);

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  } catch (error) {
    if ( error instanceof InteractionRequiredAuthError ) {
      msalInstance.logoutRedirect();
    }
  }
};

export const fetchWithAuthHeader = async (url: string, options: any = {}) => {
  const headers = new Headers(options.headers || {});
  await prepareHeaders(headers);
  
  return fetch(url, {
    ...options,
    headers
  });
};
