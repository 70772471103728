import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { AppRoutes } from './Routes';
import { PageLayout } from './components/layout/PageLayout';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { BrowserRouter } from 'react-router-dom';
import { ReactElement } from 'react';
import NotificationsProvider from './components/notificationsProvider/NotificationsProvider';
import './css/App.css';

function App(): ReactElement {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']} minBreakpoint="xs">
          <PageLayout>
            <NotificationsProvider>
              <AppRoutes />
            </NotificationsProvider>
          </PageLayout>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
