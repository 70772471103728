import Container from 'react-bootstrap/Container';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Dashboard } from '../components/dashboard/Dashboard';
import { ReactElement } from 'react';

export const HomePage = (): ReactElement => {
  return (
    <>
      <UnauthenticatedTemplate>
        <Container className="p-3"></Container>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Tabs id="controlled-tab-example" defaultActiveKey={'dashboard'} className="mb-3">
          <Tab eventKey="dashboard" title="Dashboard">
            <Dashboard />
          </Tab>
        </Tabs>
      </AuthenticatedTemplate>
    </>
  );
};
