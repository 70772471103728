import Navbar from 'react-bootstrap/Navbar';
import { Container } from 'react-bootstrap';
import '../../css/App.css';
import { ReactElement } from 'react';

export const Footer = (): ReactElement => {
  return (
    <footer className="csew-footer">
      <Navbar expand="lg" className="mt-3 p-3 csew-grey-brand-color">
        <Container>{new Date().getFullYear()} Verian Group. All rights reserved.</Container>
      </Navbar>
    </footer>
  );
};
