import { Container, Tab, Tabs } from 'react-bootstrap';
import { CodingContainer } from '../components/coding/CodingContainer';
import { ReactElement, useState } from 'react';
import { useGetRespondentsQuery } from '../store/apis';
import { Loader } from '../components/Loader';
import { useLocation, useParams } from 'react-router-dom';
import { VictimForms } from '../components/victim-form/VictimForms';
import { getCodingStatusLabel, getSurveyTypeLabel, getErrorStatus, notificationMessages } from '../helpers';
import { useNotificationOnErrorFromServer } from '../hooks';

export const CodingPage = (): ReactElement => {
  const location = useLocation();
  const { statusId } = useParams();

  const [path, setPath] = useState(location.pathname);

  const { data, isLoading, error } = useGetRespondentsQuery({ url: path, status: statusId });
  useNotificationOnErrorFromServer(error, 401, 
    notificationMessages.unauthorized.header, 
    notificationMessages.unauthorized.message);

  if (error && getErrorStatus(error) === 401) {
    return <>You do not have permission to view this page.</>;
  }

  if (isLoading) {
    return <Loader />;
  }
  if (!data) {
    return <>No data</>;
  }

  const title =
    data?.[0] &&
    `${data?.[0]?.SurveyHeader} (${getSurveyTypeLabel(data?.[0]?.Survey_Type)}) - ${getCodingStatusLabel(statusId)}`;
  return (
    <Container>
      <h4 className="mb-5 pt-4">{title}</h4>
      <Tabs defaultActiveKey={'coding'} className="mb-3">
        <Tab eventKey="coding" title="Coding">
          <CodingContainer data={[...data]} setPath={setPath} />
        </Tab>
        <Tab eventKey="victimForm" title="Victim Forms">
          <VictimForms data={data} />
        </Tab>
      </Tabs>
    </Container>
  );
};
