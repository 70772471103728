import { useGetLockedRecordsQuery } from '../../store/apis';
import { Card, Table } from 'react-bootstrap';
import { Loader } from '../Loader';
import { ReactElement } from 'react';
import { ILockedRecord } from '../../types';
import { ReferralsReport } from '../referralsReport/ReferralsReport';
import { useNotificationOnErrorFromServer } from '../../hooks';
import { getErrorStatus, notificationMessages } from '../../helpers';
import moment from 'moment';

export const LockedRecords = (): ReactElement => {
  const { data, isLoading, error } = useGetLockedRecordsQuery();

  useNotificationOnErrorFromServer(error, 401,
    notificationMessages.unauthorized.header, 
    notificationMessages.unauthorized.message);

  if (isLoading) {
    return <Loader />;
  }


  if (error && getErrorStatus(error) === 401) {
    return <>You do not have permission to view this page.</>;
  }

  if (!data?.length) {
    return <ReferralsReport />
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Locked Records</Card.Title>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial</th>
                <th>Locked By</th>
                <th>Locked At</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: ILockedRecord) => {
                  return (
                    <tr key={item.Serial_Num}>
                      <td>{item.Serial_Num}</td>
                      <td>{item.Locked_By}</td>
                      <td>{moment(item.Locked_At).format('DD/MM/YYYY h:mm:ss')}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <ReferralsReport />
    </>
  );
};
