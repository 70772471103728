import { ReactElement, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useGetDateLimitsQuery, usePostDownloadMutation } from '../store/apis';
import { useForm } from 'react-hook-form';
import { File as FileUpload } from '../components/form/File';
import { Date } from '../components/form/Date';
import { Loader } from '../components/Loader';
import moment from 'moment';
import { useNotificationOnErrorFromServer } from '../hooks';
import { getErrorStatus, notificationMessages } from '../helpers';

export const DownloadPage = (): ReactElement => {
  const [isValidationErrorVisible, setIsValidationErrorVisible] = useState<boolean>(false);
  const [postDownload, { isSuccess, data, isLoading, isError }] = usePostDownloadMutation();
  const { data: dateData, isLoading: dateIsLoading, error: dateError } = useGetDateLimitsQuery();
  const { handleSubmit, control } = useForm();
  const minDate = moment(dateData?.MinDate).format('YYYY-MM');
  const maxDate = moment(dateData?.MaxDate).format('YYYY-MM');
  const notFoundForms = data?.NotFoundForms?.map((item: any) => item.CapiSerial)?.join(', ');

  const onSubmit = async (data: any): Promise<any> => {
    const {SelectFrom, SelectTo, FileUpload} = data;
    if(!SelectFrom || !SelectTo || !FileUpload) {
      setIsValidationErrorVisible(true);
      return;
    }
    const fromDate: string[] = SelectFrom ? SelectFrom?.split('-') : ['0', '0'];
    const toDate: string[] = SelectTo ? SelectTo?.split('-') : ['0', '0'];
    if (FileUpload?.[0]?.size < 102400) {
      const formData = new FormData();
      formData.append('file', FileUpload?.[0]);
      await postDownload({ body: formData, url: `${fromDate[0]}/${fromDate[1]}/${toDate[0]}/${toDate[1]}` });
    }
  };

  useNotificationOnErrorFromServer(dateError, 401, 
    notificationMessages.unauthorized.header, 
    notificationMessages.unauthorized.message);

  useEffect(()=> {
    if(isValidationErrorVisible) {
      setTimeout(() => {
        setIsValidationErrorVisible(false);
      }, 5000)
    }
  },[isValidationErrorVisible])

  if (isSuccess) {
    const a = document.createElement('a');
    a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.FileBody}`;
    a.download = 'Victim Form.zip';
    a.click();
  }
  if (isLoading || dateIsLoading) {
    return <Loader />;
  }

  if (dateError && getErrorStatus(dateError) === 401) {
    return <>You do not have permission to view this page.</>;
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Export Victim Forms</Card.Title>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <Date control={control} name="SelectFrom" label="Select month/year" text="From" min={minDate} max={maxDate} />
              </Col>
              <Col>
                <Date control={control} name="SelectTo" label="Select month/year" text="To" min={minDate} max={maxDate} />
              </Col>
            </Row>
            <FileUpload control={control} name="FileUpload" isError={isError} message={notFoundForms} />
            <Button className="mt-3 csew-button csew-button_secondary" type="submit">
              Upload Serials
            </Button>
            {isValidationErrorVisible && (
              <div>
                <div className='csew-download-validation-error'>All fields should be filled</div>
              </div>
            )}
          </Form>
        </Card.Body>
        <Card.Body>
          <a href={`${process.env.PUBLIC_URL}/Template.xlsx`} download="Template.xlsx">
            <Button className='csew-button csew-button_tertiary'>Template</Button>
          </a>
        </Card.Body>
      </Card>
    </>
  );
};
