import React from 'react';
import { Card, ListGroup, ListGroupItem, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { ReactElement, useState } from 'react';
import { getCodingStatusLabel, getSurveyTypeLabel } from '../../helpers';
import NewWindow from 'rc-new-window';
import { VictimForm } from '../victim-form/VictimForm';
import { useParams } from 'react-router-dom';
import { QuestionCircle } from 'react-bootstrap-icons';

interface IProps {
  data: any;
}
export const DetailsTable = ({ data }: IProps): ReactElement => {
  const { statusId } = useParams();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [victimFormData, setVictimFormData]: any = useState([]);
  const handleOpenForm = (data: any): void => {
    setVictimFormData(data);
    setIsPopupOpen(true);
  };

  const title =
    data?.[0] && `${data?.[0]?.SurveyHeader} (${getSurveyTypeLabel(data?.[0]?.Survey_Type)}) - ${getCodingStatusLabel(statusId)}`;
  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          To view details of the offence, hover over the information icon to view a snippet, or click a serial number to view the victim
          form in full
        </Card.Text>
        <Card.Text>
          <Table bordered size="sm">
            <thead>
              <tr style={{ borderBottomWidth: 3, borderBottomColor: 'black' }}>
                <th>Serial</th>
                <th>Type</th>
                <th>Verian Code</th>
                <th>ONS Code</th>
                <th>Final Code</th>
                <th>Comments</th>
                <th>Verian Cyber Flag</th>
                <th>Final Cyber Flag</th>
                <th>Initial Coder</th>
              </tr>
            </thead>
            <tbody>
              {
                data.map(({Respondents, Survey_Type}: any) => (
                  <React.Fragment key={Survey_Type}>
                    {Respondents?.map((respondent: any) => (
                      <>
                        <tr style={{ borderBottomWidth: 2, borderBottomColor: 'gray' }} key={respondent.RespDbID}>
                          <td className="bg-light" colSpan={9}>
                            Survey Type: {Survey_Type}; Resp ID: {respondent.Resp_ID}
                          </td>
                        </tr>
                        {respondent?.VictimForms?.map((item: any) => (
                          <tr key={item.Serial_Num}>
                            <td>
                              <Card.Link className="mx-2" style={{ cursor: 'pointer' }} onClick={() => handleOpenForm(item)}>
                                {item.Serial_Num}
                              </Card.Link>
                              <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                  <Tooltip className="csew-tooltip" id={'tooltip-bottom'}>
                                    {item.Offence_Snippet}
                                  </Tooltip>
                                }
                              >
                                <QuestionCircle />
                              </OverlayTrigger>
                            </td>
                            <td>{item.Form_Classification}</td>
                            <td>{item.TNS_Code_And_Label}</td>
                            <td>{item.ONS_Code_And_Label}</td>
                            <td>{item.Final_Code_And_Label}</td>
                            <td>
                              {item?.Comments?.split('--//--')?.map((item: any) => (
                                <div>{item}</div>
                              ))}
                            </td>
                            <td>{item.TNS_Cyber_Flag_Label}</td>
                            <td>{item.Final_Cyber_Flag_Label}</td>
                            <td>{item.UID}</td>
                          </tr>
                        ))}
                      </>
                    ))}
                  </React.Fragment>
                ))
              }
            </tbody>
          </Table>
        </Card.Text>
      </Card.Body>
      {isPopupOpen && (
        <NewWindow onClose={() => setIsPopupOpen(false)}>
          <VictimForm data={victimFormData} />
        </NewWindow>
      )}
    </Card>
  );
};
