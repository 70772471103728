import { Col, Form, ListGroup, ListGroupItem } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import { IFormTextArea } from '../../types';
import { ReactElement } from 'react';

export const FormTextArea = ({
  label,
  size,
  disabled,
  control,
  index,
  name,
  field: defaultValue,
  validate,
  showDialog = false,
}: IFormTextArea): ReactElement => {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({
    name: `respondents.${index}.${name}`,
    control,
    rules: validate ? { validate } : {},
  });
  const {value, ...otherFieldParams} = field;

  return (
    <>
      <Form.Label column sm={1} className="csew-text-align-right">
        {label}
      </Form.Label>
      <Col sm={size} className="position-relative">
        <ListGroup className="mb-3">
          {showDialog && defaultValue[label]?.split('--//--')?.map((item: any, index: number) => <ListGroupItem key={index}>{item}</ListGroupItem>)}
        </ListGroup>
        <Form.Control as="textarea" maxLength={500} rows={2} disabled={disabled} value={value || ''} {...otherFieldParams} isInvalid={invalid} />
        <Form.Control.Feedback type="invalid" tooltip>
          {error?.message}
        </Form.Control.Feedback>
      </Col>
    </>
  );
};
