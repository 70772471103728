import { Tab, Tabs } from 'react-bootstrap';
import { ReactElement } from 'react';
import { VictimForm } from './VictimForm';

export const VictimForms = ({ data }: any): ReactElement => {
  return (
    <Tabs defaultActiveKey={data[0]?.Form_Num} className="mb-3">
      {data.map((item: any) => (
        <Tab key={item.Serial_Num} eventKey={item.Form_Num} title={item.Form_Num}>
          <VictimForm data={item} />
        </Tab>
      ))}
    </Tabs>
  );
};
