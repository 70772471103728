import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from './helpers';
import { IRespondent } from '../types';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getRespondents: builder.query<IRespondent[], any>({
      query: (arg) => arg.url,
      transformResponse: (response: IRespondent[], meta, arg) => {
        return response.map((item: IRespondent) => item.Coding_Status === arg.status ? item : {...item, ReadOnly: true})},
    }),
    getDetails: builder.query<any, string>({
      query: (url) => url,
    }),
    postDownload: builder.mutation<any, any>({
      query: (arg) => ({
        url: `/download-victim-forms/${arg.url}`,
        method: 'POST',
        body: arg.body,
      }),
    }),
    getVictimForm: builder.query<any, string>({
      query: (url) => `/victim-form-data/${url}`,
    }),
    postRespondent: builder.mutation<IRespondent[], string>({
      query: (body) => ({
        url: '/respondent',
        method: 'POST',
        body,
      }),
    }),
    syncVictimFormDataToDimensions: builder.mutation<any, string>({
      query: (respondentId) => `/initial-coding-data/${respondentId}`,
    }),
    getDashboard: builder.query<any, void>({
      query: () => '/victim-form/dashboard',
    }),
    getUser: builder.query<any, void>({
      query: () => '/user',
    }),
    getLockedRecords: builder.query<any, void>({
      query: () => '/report/locked-records',
    }),
    getDateLimits: builder.query<any, void>({
      query: () => '/min-max-vf-dates',
    }),
    getReportList: builder.query<any, void>({
      query: () => '/download-report/get-report-list',
    }),
    getCodeFrames: builder.query<any, any>({
        query: (surveyType) => `/code-frames/${surveyType}`,
      transformResponse: (response: any, meta, arg) =>
        arg === 'A'
          ? response
              ?.filter((item: any) => item?.Survey_Type === arg)
              .map((item: any) => {
                return {
                  value: item.Code,
                  label: `${item.Code}: ${item.Code_Label}`,
                };
              })
          : response.map((item: any) => {
              return {
                value: item.Code,
                label: `${item.Code}: ${item.Code_Label}`,
              };
            }),
    }),
  }),
  refetchOnMountOrArgChange: true,
});

export const {
  useGetRespondentsQuery,
  useGetDetailsQuery,
  usePostRespondentMutation,
  useSyncVictimFormDataToDimensionsMutation,
  usePostDownloadMutation,
  useGetDashboardQuery,
  useGetCodeFramesQuery,
  useGetUserQuery,
  useGetLockedRecordsQuery,
  useGetVictimFormQuery,
  useGetDateLimitsQuery,
  useGetReportListQuery,
} = api;
