import { Header } from './Header';
import { Container } from 'react-bootstrap';
import '../../css/App.css';
import { ReactElement } from 'react';
import { Footer } from './Footer';
import { AuthenticatedTemplate } from '@azure/msal-react';

export const PageLayout = (props: any): ReactElement => {
  return (
    <>
      <Header />
      <AuthenticatedTemplate>
        <Container className="csew-container">{props.children}</Container>
      </AuthenticatedTemplate>
      <Footer />
    </>
  );
};
