import { Col, Form } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import { IFormInput } from '../../types';
import { ReactElement } from 'react';

export const FormInput = ({ label, size, disabled, control, index, name }: IFormInput): ReactElement => {
  const { field } = useController({
    name: `respondents.${index}.${name}`,
    control,
  });
  return (
    <>
      <Form.Label column sm={1} className="csew-text-align-right">
        {label}
      </Form.Label>
      <Col sm={size}>
        <Form.Control disabled={disabled} {...field} />
      </Col>
    </>
  );
};
